import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, of } from 'rxjs';

import { User } from '../interfaces/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private readonly _keycloakService: KeycloakService
  ) {}

  apiKeycloakUrl = 'https://keycloak.sigl.epita.fr/admin/realms/RE/users';
  apiUserUrl = environment.userUrl;
  apiData: any;

  getToken() {
    this._keycloakService.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          this._keycloakService.updateToken(20);
        }
      },
    });
  }

  getAllUsers() {
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    this.http.get(this.apiKeycloakUrl, { headers }).subscribe(
      (data) => {
        this.apiData = data;
        console.log(this.apiData);
      },
      (error) => {
        console.log('Error getting API data:', error);
      }
    );
  }

  addUser(body: User) {
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    const bodyFiltered = Object.fromEntries(
      Object.entries(body).filter(
        (entry) => entry[1] !== null && entry[1] !== '' && entry[1] !== '[]'
      )
    );

    return this.http.post(this.apiKeycloakUrl, bodyFiltered, {
      headers,
      observe: 'response',
    });
  }

  exportUsersToBack(csvFile: FormData): Observable<any> {
    const headers = new HttpHeaders();

    return this.http.post(`${this.apiUserUrl}/upload`, csvFile, { headers });
  }

  deleteUser(userId: string) {
    const url = `${this.apiKeycloakUrl}/${userId}`;
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    return this.http.delete(url, { headers });
  }

  getRole(): Observable<any> {
    const apiGroupsUrl =
      'https://keycloak.sigl.epita.fr/admin/realms/RE/groups';
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    return this.http.get(apiGroupsUrl, { headers });
  }

  getUsersFromRole(groupId: string): Observable<any> {
    const apiGetUsersGroupURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/groups/${groupId}/members`;
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    return this.http.get(apiGetUsersGroupURL, { headers });
  }

  getUserFromId(userId: string): Observable<any> {
    const apiGetUserIdURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/users/${userId}`;
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);
    const response_k = this.http.get(apiGetUserIdURL, { headers });
    return this.http.get<any>(`${this.apiUserUrl}?id=${userId}`);
  }

  getuserGroupFromUserId(userId: string): Observable<any> {
    const apiGetUserIdURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/users/${userId}/groups`;
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    return this.http.get(apiGetUserIdURL, { headers });
  }

  editUserFromId(userId: string, newData: any): Observable<any>{
    const apiGetUserIdURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/users/${userId}`;
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);

    return this.http.put(apiGetUserIdURL, newData, { headers });
  }

  changeRoleUserId(userId: string, oldRole: string, newRole: string): Observable<any>{
    const apiDeleteRoleURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/users/${userId}/groups/${oldRole}`;
    const apiAddRoleURL = `https://keycloak.sigl.epita.fr/admin/realms/RE/users/${userId}/groups/${newRole}`;
    
    const headers = new HttpHeaders();
    this._keycloakService.addTokenToHeader(headers);
    
    this.http.delete(apiDeleteRoleURL, { headers });
    return this.http.put(apiAddRoleURL, {}, { headers });
  }
 
  getProfileId(id: number): Observable<any> {
    return this.http.get<any>(`environment.backendUrl/user?id=${id}`);
  }
  
  updateUser(firstname: string, lastname: string, userData: any): Observable<any> {
    return this.http.put(`${this.apiUserUrl}/users/${firstname}/${lastname}`, userData);
  }

  getUserFromIdByName(firstname: string, lastname: string): Observable<any> {
    return this.http.get(`${this.apiUserUrl}/users/${firstname}/${lastname}`);
  }

  getUserProfile(firstname: string, lastname: string): Promise<any> {
    return this.http
      .get(`${this.apiUserUrl}/users/profile`, {
        params: { firstname, lastname },
      })
      .toPromise();
  }

  getReferents(): Observable<any> {
    return this.http.get(`${this.apiUserUrl}/users/referents`);
  }
}
