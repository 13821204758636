import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profil',
    loadChildren: () => import('./components/profil/profil.module').then((m) => m.ProfilModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'homepage',
    loadChildren: () => import('./components/homepage/homepage.module').then((m) => m.HomepageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'convention',
    loadChildren: () => import('./components/stage/stage.module').then((m) => m.StageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stages',
    loadChildren: () => import('./components/stage/stage-list/stage-list.module').then((m) => m.StageListModule),
    canActivate: [AuthGuard],
    data: { authorizedRoles: ['admin', 'school', 'referent'] },
  },
  {
    path: 'referent',
    loadChildren: () =>
      import('./components/stage/stage-referent/stage-referent.module').then((m) => m.StageReferentModule),
    canActivate: [AuthGuard],
    data: { authorizedRoles: ['admin', 'school', 'referent'] },
  },
  {
    path: 'users',
    loadChildren: () => import('./components/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard],
    data: { authorizedRoles: ['admin', 'school'] },
  },
  {
    path: 'logs',
    loadChildren: () => import('./components/logs/logs.module').then((m) => m.LogsModule),
    canActivate: [AuthGuard],
    data: { authorizedRoles: ['admin'] },
  },
  {
    path: 'forms-management',
    loadChildren: () => import('./components/forms/forms.module').then((m) => m.FormsModule),
    canActivate: [AuthGuard],
    data: { authorizedRoles: ['admin', 'school'] },
  },
  {
    path: 'consignes',
    loadChildren: () => import('./components/consignes/consignes.module').then((m) => m.ConsignesModule),
    canActivate: [AuthGuard],
  },
  //This is the route for the template
  {
    path: 'template-table',
    loadChildren: () => import('./components/table-template/template.module').then((m) => m.templateModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'template-add',
    loadChildren: () => import('./components/input-template/input-template.module').then((m) => m.inputTemplateModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'session/:uid',
    loadChildren: () =>
      import('./components/stage/internship-session/internship-session.module').then((m) => m.InternshipSessionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./components/access-denied/access-denied.module').then((m) => m.AccessDeniedModule),
  },
  {
    path: '**',
    redirectTo: '/homepage',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
